import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import Navbar from './components/Navbar/Navbar';
import FullScreenVideoBackground from './components/FullScreenVideoBackground/FullScreenVideoBackground';
import PricingSection from './components/PricingSection/PricingSection';
import Footer from './components/Footer/Footer';
import ContactForm from './components/ContactForm/ContactForm';
import About from './components/About/About';
import reportWebVitals from './reportWebVitals';

// Mount each component only if its respective mount point exists
const mountComponent = (component, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    const root = ReactDOM.createRoot(element);
    root.render(
      <React.StrictMode>
        {component}
      </React.StrictMode>
    );
  }
}

mountComponent(<Navbar />, 'navbar-root');
mountComponent(<App />, 'root');
mountComponent(<FullScreenVideoBackground />, 'background-root');
mountComponent(<PricingSection />, 'pricing-root');
mountComponent(<About />, 'about-root');
mountComponent(<ContactForm />, 'contact-root');
mountComponent(<Footer />, 'footer-root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
