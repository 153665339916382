import React, { useState, useEffect } from 'react';
import './FullScreenVideoBackground.css'; // Make sure to create this CSS file

const FullScreenVideoBackground = () => {
  const [backgrounds, setBackgrounds] = useState([]);

  useEffect(() => {
    const apiUrl = 'https://cmbtricee.com';
    // const apiUrl = 'http://127.0.0.1:8000/';
    // const apiUrl = process.env.REACT_APP_API_URL;

    fetch(`${apiUrl}/background/`)
      .then(response => response.json())
      .then(data => setBackgrounds(data))
      .catch(error => console.error('Error fetching background:', error));
  }, []);

  const renderBackground = (background) => {
    const isVideo = background.imageFile.endsWith('.mp4');
    return isVideo ? (
      <video autoPlay muted loop playsInline className="video-background" key={background.id}>
        <source src={background.imageFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img src={background.imageFile} alt="background" className="image-background" key={background.id} />
    );
  };

  return (
    <div className="video-background-container home-section" id="home">
      {backgrounds.map(background => renderBackground(background))}
      <div className="overlay-text">
        <strong><h1 className="animate-text">Welcome to Our Site</h1></strong>
        <strong><p className="animate-text">Explore the experience</p></strong>
      </div>
    </div>
  );
};

export default FullScreenVideoBackground;