import React, { useState } from "react";
import { useSpring, animated } from 'react-spring';
import "./ContactForm.css"; 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    honey: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [showMessage, setShowMessage] = useState(false);

  const messageAnimation = useSpring({
    opacity: showMessage ? 1 : 0,
    transform: showMessage ? `translateY(0)` : `translateY(-20px)`,
    config: { tension: 280, friction: 60 }
  });

  const handleSubmit = (e) => {

    e.preventDefault();

    if (formData.honey) {
      console.log("Honeypot field was filled out.");
      return; 
    }

    // Basic validation check
    if (!formData.name || !formData.email || !formData.message) {
      alert("Please fill out all fields.");
      return;
    }

    // Get the API URL from environment variable
    // const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrl = 'https://cmbtricee.com';


    console.log("apiurl: ", apiUrl);

    fetch(`${apiUrl}/send-email/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setSubmitted(true); 
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 5000);
        setFormData({
          name: "",
          email: "",
          message: "",
          honey: "",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (submitted && showMessage) {
    return (
      <animated.div style={messageAnimation}>
        <p>Thank you for your message! We will get back to you soon.</p>
      </animated.div>
    );
  }

  return (
    <>
      {submitted && showMessage ? (
        <animated.div style={messageAnimation}>
          <p>Thank you for your message! We will get back to you soon.</p>
        </animated.div>
      ) : (
    <div className="contact-section" id="contact">
    <form className="contact-form contact-section" id="contact" onSubmit={handleSubmit} >
      <label htmlFor="name">Name</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <label htmlFor="message">Message</label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleChange}
      />

      <div className="honeypot" style={{ display: "none" }}>
        <label htmlFor="honey">Honey</label>
        <input
          type="text"
          id="honey"
          name="honey"
          value={formData.honey || ""}
          onChange={handleChange}
        />
      </div>

      <button type="submit">Send</button>
    </form>
    </div>
      )}
    </>
  );
};

export default ContactForm;
