import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PricingSection.css';

const PricingSection = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {

    const apiUrl = 'https://cmbtricee.com'
    // const apiUrl = process.env.REACT_APP_API_URL;

    fetch(`${apiUrl}/product/`)
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true, // Enables centered view with partial prev/next slides
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="pricing-section" id="pricing">
      <Slider {...settings}>
        {products.map(product => (
          <div key={product.id} className="product">
            <img src={product.image} alt={product.name} />
            <h2>{product.name}</h2>
            <p>Price: ${product.price}</p>
            <p>{product.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PricingSection;
